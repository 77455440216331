<template>
	<b-row>
		<b-col cols="12">
			<b-card class="position-relative" no-body style="height: calc(100vh - 20rem)">
				<b-table :items="items" hover striped></b-table>
				<b-pagination v-model="currentPage" :total-rows="rows" align="right" class="position-absolute" first-number hide-goto-end-buttons last-number next-class="next-item" prev-class="prev-item" style="right: 1rem; bottom: 0" />
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
	import { reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "index",
		setup() {
			const data = reactive({
				items: [
					{ age: 40, first_name: "Dickerson", last_name: "Macdonald" },
					{ age: 21, first_name: "Larsen", last_name: "Shaw" },
					{ age: 89, first_name: "Geneva", last_name: "Wilson" },
					{ age: 38, first_name: "Jami", last_name: "Carney" },
				],
				currentPage: 1,
				rows: 1,
			});
			return {
				...toRefs(data),
			};
		},
	};
</script>

<style scoped></style>
